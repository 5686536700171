.select-all {
    text-align: center;
    padding: 0 10px;
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }
  
  .counterStyle {
    position: absolute;
    right: 66px;
    padding: 0px 6px;
    background-color: rgb(235, 235, 235);
    border-radius: 8px;
    font-family: initial;
  }
  
  .valueContainerStyle {
    position: relative;
    width: 100%;
    display: flex;
  }
  
  .valueStyle {
    padding-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50%;
  }
.notifi-panel-div {
  width: 95%;
  margin-inline: auto;
  margin-top: 2%
}

.notification-results {
  margin-block-start: 30px;
}

.btn-notifi-filter {
  margin-top: 27px;
}

.settings-btn {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .notifi-panel-div {
    margin-left: 100px;
  }
  .notifi-results-div {
    width: 90%;
    margin-top: 20px;
  }
  .btn-notifi-filter {
    margin-top: 10px;
  }
}

.admn-usr-table {
  margin-left: 5%;
  margin-top: 10px;
}
.admn-rols-table {
  height: 244px;
  width: 95%;
}
.admn-grps-table {
  height: 244px;
  width: 95%;
}

.usr-role-grp-container {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
@media only screen and (max-width: 768px) {
  .admn-rols-table {
    width: 95%;
  }
  .admn-grps-table {
    width: 95%;
    margin-top: 150px;
  }
  .admn-usr-txt {
    margin-top: 20px;
  }
}

.admn-mdl-grp {
  margin: 5%;
  overflow: hidden;
}

.admn-div-perm {
  height: 300px;
}

.grp-container {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.admn-upres-btngrp {
  margin-top: 40px;
}

.admn-grp-slct {
  margin-left: 5%;
}
.permission-table {
  margin-top: 2.5%;
}

.grp-perm-btn-grp {
  display: flex !important;
  justify-content: center;
}

.grp-perm-btn {
  margin-left: 1% !important;
}
@media only screen and (max-width: 768px) {
  .admn-btn-creategrp {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .admn-mdl-grp {
    margin: 10%;
  }

  .admn-div-perm {
    width: 80%;
  }

  .admn-grp-slct {
    max-width: 75%;
    margin-left: 0px;
  }

  .admn-upres-btngrp {
    margin-top: 140px;
  }
}

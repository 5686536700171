body {
  margin: 0;
  font-family: 'Poppins',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, ::after, ::before {
  box-sizing: border-box;
}

:root {
  --sidebar-width:42px;
  --sidebar-active-width:185px;
  --sidebar-font-color:fff;
  --sidebar-font-size:0.625rem;
  --sidebar-selected-color:#5a7c90;
  --sidebar-hover-color:#547689;
  --sidebar-background-color:#44677b;
  --sidebar-padding:12px;
  --sidebar-slide-time:200ms;

  --header-height:50px;

  --attribute-smart-filter-container-width:210px;
}

/**
 Falback for bootstrap  important for login screen to work right now
 remove once the login screen is fixed
*/

.d-md-flex {
  display: -ms-flexbox!important;
  display: flex!important;
}


.settings-inpt-grp {
  width: 65%;
}

.settings-div-cont {
  display: flex;
}

.settings-inpt-label {
  color: white;
  background-color: #5a7c90;
  font-size: 14px;
}

.settings-inpt-value:disabled {
  background-color: #dedddc;
  opacity: 0.8;
  color: black;
}

.set-btns {
  margin-left: 10px;
  color: #5a7c90;
  font-size: 25px;
}
.set-btns:hover {
  cursor: pointer;
}

.set-btn-save {
  background: none;
  padding: 0;
  border: none;
}
.set-btn-save:disabled {
  background: none;
  border: none;
  opacity: 0.4;
}

.fa-save-icon {
  color: #5a7c90;
  font-size: 22px;
  cursor: pointer;
}

.set-div-btnGrp {
  display: flex;
}

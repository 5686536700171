.navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: '';
    background: no-repeat center center;
    background-size: 100% 100%;
}

nav {
    display: block;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

.collapse:not(.show) {
    display: none;
}

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
}



/*Header css */
.navbar-nav{
  list-style-type: none;

}
  .navbar-brand {
    height: 100%;
    font-size: 16px;
    color: #44677b;
    font-weight: 600;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: space-between;
    cursor: default;

    &:hover {
      color: #44677b;
    }
  }
  .nav-item{
    color:#000000;
font-size: 0.85rem;
font-weight: 400;
font-family: 'Poppins' !important;
padding-left: 10px;
  }

    .navbar-nav {
display: flex;
flex-direction: row;
      & > .nav-item:last-child {
        padding-right: 0;
      }
  
} 

.ticket-text {
  padding: 1% 0.5%;
  text-align: left;
}
.ticket-inp {
  padding: 1% 0.5%;
  text-align: left;
  width: 80%;
  background-color: #fff;
}
.input-group {
  text-align: left;
  margin-bottom: 1rem;
}

.ticket-btn {
  margin: 2%;
  padding: 0.5% 2%;
  background-color: #44677b;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.ticket-form {
  margin: 10%;
  margin-top: 2%;
  text-align: left;
  flex-flow: row;
  width:40%
}
.ticket-desc {
  width: 80%;
  height: 70%;
  padding: 1% 0.5%;
  text-align: left;
}
.ticket-button {
  margin-right: 2% !important;
}
.filterContainer{
    margin-top: 10px;
}
.resultContainer{
    margin: 35px;
}
.filter-row{
display: flex;
flex-wrap: wrap;

}
.filter-col{
width:22%;
margin-right: 1em;
margin-left: 1em;
margin-bottom: 1em;

}

/*----------------login css------------------*/
.logo-img-left {
  width: 24%;
  margin: 2%;
}
#leftImgDiv {
  background-size: cover;
  background-position: center;
}

#forgtpas {
  float: right;
}
/* 
#btnDivContact {
  text-align: center;
  padding: 10px;
  width: 100%;
} */

#titleColor {
  color: #4f677b;
  font-weight: 600;
}

.navTopLeft {
  padding-top: 14px;
}

.app-info-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  margin: auto;
  height: 50%;
  h3 {
    display: flex;
    justify-content: center;
  }
}
.app-info {
  margin: auto;
}

.btn-contact {
  padding-right: 42px;
  padding-left: 40px;
  margin-top: 40px;
  color: #44677b;
  font-weight: 700;
  background-color: #f3f0f0 !important;
  height: 35px;
  border-radius: 3px;
  border: 1px solid transparent;
  font-size: 1rem;
}

.copyright-text {
  font-size: 0.8rem;
  margin: auto;
}

.sign-in-text {
  margin: 0;
  font-weight: 500;
}
.signin-form-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.signin-form-container {
  background-color: #fbfbfb !important;
  display: flex;
  justify-content: center;
  padding-top: 2%;
  p,
  label {
    font-size: 0.8rem;
  }
}
.signin-form {
  input {
    background: #ffffff;
    box-shadow: 0 1px 15px 1px rgb(62 57 107 / 7%);
    border-radius: 4px;
    height: 41px;
    box-sizing: border-box;
  }
  .input-group {
    margin-bottom: 1rem;
  }
  .btn-signin {
    padding-right: 42px;
    padding-left: 40px;
    margin-top: 10px;
    background-color: #44677b;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-weight: 700;
    height: 35px;
    border-radius: 3px;
    border: 1px solid transparent;
    font-size: 1rem;
    cursor: pointer;
  }
  .btn-signin:disabled {
    opacity: 0.3;
  }
}

.login-options {
  width: 60%;
  margin: 0 auto;
}

.firebase-sign-in {
  width: 60%;
  margin-top: 6%;
  display: flex;
  justify-content: flex-start;
  .firebaseui-card-content {
    padding: 0;
  }
}

.forgot-pass-disabled {
  pointer-events: none;
  opacity: 0.6;
}

#btnReset {
  margin-top: 10px;
  color: #44677b;
  font-weight: 500;
  font-size: 14px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

#btnReset:disabled {
  opacity: 0.3;
}

.h-md-100 {
  height: 100vh;
}

.error-text {
  color: red;
  font-size: 12px;
  margin-top: 3px;
}
.error-border {
  border: 1px solid #ffc0cb !important;
  box-shadow: 0 1px 15px 1px rgb(255 0 0 / 7%);
}
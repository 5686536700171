.md-ui.component-data-table {
    background: #fff;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin: 2em 0;
    width: auto;
    /* START - main table header */
    .main-table-header {
        padding: 24px 14px 24px 24px;
        display: inline-flex;
        align-items: center;
        position: relative;
        width: 100%;
        .table-header--title {
            color: rgba(0, 0, 0, 0.87);
            font-weight: 100;
            font-size: 20px;
            margin: 0;
            display: inline-block;
        }
        .table-header--action {
            font-size: 24px;
            vertical-align: middle;
            margin-left: 6px;
        }
        .table-header--icons {
            font-size: 12px;
            float: right;
            vertical-align: middle;
            .material-icons:first-child {
                padding-right: 24px;
            }
            position: absolute;
            right: 24px;
        }
    }
    /* END - main table header */
    /* START - main table wrapper */
    .main-table-wrapper {
        display: block;
        max-width: 100%;
        overflow-x: auto;
        /* START data table content */
        .main-table-content {
            border-collapse: collapse;
            border-spacing: 0;
            width: 100%;
            overflow: hidden;
            tr {
                border-bottom: 1px solid #e0e0e0;
                td {
                    border-bottom: none;
                    border-right: none;
                    text-overflow: ellipsis;
                    padding: 0 46px 0 0;
                    cursor: default;

                    &:hover {
                        cursor: default !important;
                    }

                    &:first-child {
                        padding: 0 12px 0 24px;
                    }
                    &:last-child {
                        padding: 0 24px 0 0;
                    }
                    &.datatype-string {
                        text-align: left;
                    }
                    &.datatype-numeric {
                        text-align: center;
                    }
                    &.datatype-info {
                        text-align: center;
                        font-size: 14px;
                        margin: 20px;
                        padding: 80px 0 20px 0;

                        span:hover, div:hover{
                            cursor: default;
                        }
                    }
                    &.datatype-info:hover {
                        background:#fff;
                        cursor: default;
                    }
                    // .table__batchname > .table__batchname--body > a {
                    //     text-decoration: none;
                    // }
                    .table__batchname--body {
                        // margin-right: 50px;
                        // display: flex;
                        // flex-direction: column;
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis; 
                        max-width: 220px;
                        min-width: 220px;
                        cursor: default;

                        a {
                            text-decoration: none;
                            .batchname {
                                font-size: 13px;
                                position: relative;
                                overflow-wrap: break-word;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }

                        }
                        .created-on {
                            text-decoration: none;
                            font-size: 10px;
                            cursor: default;
                            &:hover {
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }
                    }

                    .table__actions {
                        display: flex;
                        //color: grey;
                        justify-content: center;

                        // & > * {
                        //     margin: 0 10px;
                        // }

                        // & > :first-child {
                        //     margin: 0 10px 0 0;
                        // }

                        // & > :last-child {
                        //     margin: 0 0 0 10px;
                        // }
                    }

                    .table__images_tagged {
                        position: relative;
                    }
                }
            }
            /* START - data table header */
            .data-table-header {
                tr {
                    height: 64px;
                    padding: 0 24px;
                    td {
                        color: rgba(0, 0, 0, 0.54);
                        text-align: center;
                        font-size: 13px;
                        font-weight: 500;
                        vertical-align: middle;
                        .material-icons {
                            font-size: 18px;
                            vertical-align: middle;
                            padding-right: 8px;
                        }

                        &:first-child {
                            text-align: left;
                        }
                    }
                }
            }
            /* END - data table header */
            /* START - data table content */
            .data-table-content {
                tr {
                    height: 48px;
                    //cursor:pointer;
                    &:hover {
                        background: #eee;
                    }
                    td {
                        color: rgba(0, 0, 0, 0.87);
                        //font-weight: 500;
                        input[type='text'] {
                            background: transparent;
                            border: 0;
                            color: #212121;
                            font-family: 'Roboto', sans-serif;
                            font-size: 13px;
                            font-weight: 400;
                            height: 40px;
                            &::placeholder {
                                color: rgba(0, 0, 0, 0.38);
                            }
                        }

                        &:hover {
                            cursor: default !important;
                        }
                    }
                }
            }
            /* END data table content */
        }
    }
    /* END main table wrapper */
    /* START main table footer */
    .main-table-footer {
        color: rgba(0, 0, 0, 0.54);
        font-size: 12px;
        padding: 11px 22px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        span {
            //vertical-align: middle;
            display: flex;
            align-items: center;

            .material-icons {
                font-size: 24px;
                vertical-align: middle;
                cursor: pointer;
            }
            &.rows-selection {
                padding-right: 32px;
                .rows-selection-label {
                    padding-right: 6px;
                }
            }
            &.rows-amount {
                padding-right: 32px;
            }
            &.table-pagination {
                padding-right: 14px;
                .material-icons:first-child {
                    padding-right: 24px;
                }
            }
        }
    }
    /* END main table footer */
}


.MyReactTableClass {
    .-sort-desc {
      box-shadow: none !important;
      &:before {
        content: "▼";
        float: right;
      }
    }
  
    .-sort-asc {
      box-shadow: none !important;
      &:before {
        content: "▲";
        float: right;
      }
    }
  }
.pagination-btns {
  width: 100%;
  margin-top: 10px;
}

.pagination-text {
  margin-top: 15px;
}

.pagination-slct-pgsize {
  margin-top: 15px;
}
.expandedPosition{
   padding-left: 1rem;
}

.no-data-Container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 5rem;
  border-bottom: 1px solid #accee7;
  border-left: 1px solid #accee7;
  border-right: 1px solid #accee7;
}

.btn-style {
  margin: 3px;
  width: 35px;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid black;
}

.table-container {
  display: block;
  max-width: 100%;
  height: 100%;
  overflow: auto;
}
.maintable {
  width: 100%;
  border-spacing: 0;
  border: 1px solid #c4ced5;
}

.table-head {
  padding: 0 0.5rem;
  margin: 0;
  background-color: #e7eef2;
  border-bottom: 1px solid #accee7;
  border-right: 1px solid #accee7;
  font-size: 12px;
  text-align: center;
  white-space: nowrap !important;
}

.table-body {
  padding: 0 1rem ;
  margin: 0;
  border-bottom: 1px solid #accee7;
  border-right: 1px solid #accee7;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
}

tr:last-child td {
  border-bottom: 0;
}
th,
td {
  margin: 0;
  padding: 0 0rem;
  border-bottom: 1px solid #accee7;
  border-right: 1px solid #accee7;
  font-size: 12px;
}

.btns-block {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.btns-block button {
  margin: 5px;
}

.btn-cancel {
  border: 1px solid #44677b;
  background-color: #e2edf4 !important;
  color: #123649 !important;
}

.btn-cancel:hover {
  border: 1px solid #44677b;
  background-color: #e2edf4 !important;
  color: #123649 !important;
}

tr :hover {
  cursor: pointer;
}

.rt-thead {
  overflow-y: scroll;
}

.table-header {
  padding-block: 1%;
}
.tnt-acc-cont {
  margin-top: 10px;
  height: 400px;
  overflow-y: auto;
}

.tnt-acc-chev {
  cursor: pointer;
}

@media only screen and (max-width: 750px) {
  .tnt-acc-div {
    margin-left: 40px;
    margin-right: 40px;
  }
}

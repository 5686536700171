.wrapper {
 display:flex;
  flex-direction:row;
}


.layout {
    display:flex;
    flex-direction:column ;
    min-height:100vh;
    flex :1;
   }

.main-content {
    background:#f9f9f9;
    flex :1;
   }